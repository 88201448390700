import { useMagicKeys } from '@vueuse/core'
export default (key) => {
    const { current } = useMagicKeys()
    return {
        onListen: callback => {
            watch(current,(val) => {
                const arrayVal = Array.from(val)
                if ( arrayVal.includes(key) ) {
                    callback(arrayVal)
                }
            })
        }
    }
    
}